import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "./Skills.css";
import allSkills from "../data/SkillsData";

const Skills = () => {
  const [active, setActive] = useState("all");

  const topSkills = allSkills.slice(0, 20);

  const filteredSkills =
    active === "all"
      ? topSkills
      : allSkills.filter((skill) => skill.type === active);

  return (
    <motion.div
      id="skills"
      className="skills-container text-white"
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 1, ease: "easeOut" }}
      viewport={{ once: true }}
    >
      <div className="skills-heading">Proficiencies</div>
      <div className="skills-section-container">
        <div className="skils-section-toggle">
          <p
            className={active === "all" ? "active-tab" : ""}
            onClick={() => setActive("all")}
          >
            My Stack
          </p>
          <p
            className={active === "frontend" ? "active-tab" : ""}
            onClick={() => setActive("frontend")}
          >
            Frontend
          </p>
          <p
            className={active === "backend" ? "active-tab" : ""}
            onClick={() => setActive("backend")}
          >
            Backend
          </p>
          <p
            className={active === "middleware" ? "active-tab" : ""}
            onClick={() => setActive("middleware")}
          >
            Middleware
          </p>
          <p
            className={active === "other" ? "active-tab" : ""}
            onClick={() => setActive("other")}
          >
            Other Tools
          </p>
        </div>
        <div className="skills-info">
          <AnimatePresence mode="wait">
            <motion.div
              key={active}
              className="all-skills"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 50 }}
              transition={{ duration: 0.5, ease: "easeOut" }}
            >
              {filteredSkills.map((skill, index) => (
                <motion.div
                  key={index}
                  className="all-skills-box"
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                  <img
                    src={skill.logo}
                    alt={`${skill.skill}-icon`}
                    className="skill-image"
                  />
                  <div className="skill-desc">
                    <p className="skill-text">{skill.skill}</p>
                    <p className="skill-desc-text">{skill.desc}</p>
                  </div>
                </motion.div>
              ))}
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </motion.div>
  );
};

export default Skills;

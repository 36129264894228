import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import "./About.css";
import { HashLink as Link } from "react-router-hash-link";
import { BrowserRouter } from "react-router-dom";

const About = () => {
  const { ref: aboutRef, inView: aboutInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const { ref: expRef, inView: expInView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const animationVariants = {
    hidden: { opacity: 0, y: 100 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 100 },
  };

  const data = [
    {
      company: "Self Employed",
      date: "2024 - Present",
      title: "Freelance Web Developer",
      desc: "As a Freelance Web Developer, I create tailored web solutions for diverse clients, overseeing projects from start to finish with a commitment to quality and timely delivery.",
    },
    {
      company: "NeuralIT",
      date: "2023 - 2024",
      title: "Associate Web Developer",
      desc: "Collaborated with a dynamic team to build and maintain robust products, CRMs, and CMS platforms. Successfully tackled high-priority bug fixes under intense pressure, ensuring seamless performance and delivering quality solutions on tight deadlines.",
      tools: [
        { icons: "/images/php.svg", class: "icon-1", title: "PHP" },
        {
          icons: "/images/javascript-1.svg",
          class: "icon-2",
          title: "JavaScript",
        },
        { icons: "/images/jquery-1.svg", class: "icon-3", title: "" },
        { icons: "/images/ajax-1.svg", class: "icon-1", title: "AJAX" },
        { icons: "/images/mysql-1.svg", class: "icon-1", title: "MySQL" },
        { icons: "/images/linux-tux-1.svg", class: "icon-1", title: "Linux" },
        { icons: "/images/drupal.svg", class: "icon-1", title: "Drupal" },
      ],
    },
  ];

  useEffect(() => {
    const btn = document.querySelector("#contact-btn");
    const handleClick = (event) => {
      event.preventDefault();

      const downloadLink = document.createElement("a");
      downloadLink.href = "./Resume.pdf";
      downloadLink.download = "Fiza_Resume.pdf";
      downloadLink.click();

      btn.classList.add("active");

      const handleAnimationEnd = () => {
        btn.classList.remove("active");
        btn.removeEventListener("animationend", handleAnimationEnd);
      };

      btn.addEventListener("animationend", handleAnimationEnd);
    };

    btn.addEventListener("click", handleClick);

    return () => {
      btn.removeEventListener("click", handleClick);
    };
  }, []);

  return (
    <BrowserRouter>
      <motion.div
        id="about"
        className="about-container text-white"
        ref={aboutRef}
        initial="hidden"
        animate={aboutInView ? "visible" : "exit"}
        variants={animationVariants}
        transition={{ duration: 2, ease: "easeOut" }}
      >
        <div className="about-wrapper">
          <motion.h1
            className="about-header"
            initial={{ y: -50, opacity: 0 }}
            animate={
              aboutInView ? { y: 0, opacity: 1 } : { y: -50, opacity: 0 }
            }
            transition={{ duration: 1.5, ease: "easeOut" }}
          >
            About ME
          </motion.h1>

          <div className="about-grid-container">
            <motion.div
              className="experience-container"
              ref={expRef}
              initial="hidden"
              animate={expInView ? "visible" : "exit"}
              variants={animationVariants}
              transition={{ duration: 2, ease: "easeOut" }}
            >
              <h1>My Experience</h1>
              {data.map((exp, index) => (
                <div key={index} className="experience">
                  <div className="experience-head">
                    <p className="company">{exp.company}</p>
                    <p className="date">{exp.date}</p>
                  </div>
                  <div className="experience-desc">
                    <h1>{exp.title}</h1>
                    <p>{exp.desc}</p>
                  </div>
                  {exp.tools && (
                    <div className="experience-tools">
                      {exp.tools.map((tool, toolIndex) => (
                        <div key={toolIndex} className="tool-wrapper">
                          <img
                            src={tool.icons}
                            alt={tool.title}
                            className={`${tool.class}`}
                          />
                          <p className="tool-title">{tool.title}</p>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </motion.div>

            <motion.div
              className="me-container"
              initial="hidden"
              animate={aboutInView ? "visible" : "exit"}
              variants={animationVariants}
              transition={{ duration: 2, ease: "easeOut", delay: 0.2 }}
            >
              <div className="my-img-container"></div>
              <div className="my-content-container">
                <h1>FIZA JOIYA</h1>
                <p>
                  I'm a developer driven by curiosity, committed to solving
                  complex problems and creating impactful digital solutions. I
                  thrive on collaboration and focus on ensuring every project is
                  both functional and user-friendly.
                </p>
                <div className="about-btns">
                  <Link className="about-cv" id="contact-btn">
                    Download CV
                  </Link>
                  <Link smooth to="#contact">
                    Connect with me
                  </Link>
                </div>
              </div>
            </motion.div>

            <motion.div
              className="github-container"
              initial="hidden"
              animate={aboutInView ? "visible" : "exit"}
              variants={animationVariants}
              transition={{ duration: 2, ease: "easeOut", delay: 0.4 }}
            >
              <div
                onClick={() =>
                  window.open(
                    "https://github.com/Fiza-04",
                    "_blank",
                    "noopener,noreferrer"
                  )
                }
                className="socials-top"
              ></div>
              <div
                onClick={() =>
                  window.open(
                    "https://www.linkedin.com/in/fiza-joiya/",
                    "_blank",
                    "noopener,noreferrer"
                  )
                }
                className="socials-bottom"
              ></div>
            </motion.div>

            <motion.div
              className="education-container"
              initial="hidden"
              animate={aboutInView ? "visible" : "exit"}
              variants={animationVariants}
              transition={{ duration: 2, ease: "easeOut", delay: 0.6 }}
            >
              <div className="services-top"></div>
              <div className="services-bottom">
                <h1>Services I Provide</h1>
                <div className="services-sub">
                  <img
                    src="/images/frontend.png"
                    alt="Frontend"
                    className="service-img"
                  />
                  <div className="services">
                    <p>Frontend Development</p>
                    <p>App Development</p>
                    <p>Backend Development</p>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </motion.div>
    </BrowserRouter>
  );
};

export default About;

import React from "react";
import { motion } from "framer-motion";
import "./Home.css";
import DownloadBtn from "../components/DownloadBtn";
import Marquee from "../components/Marquee";

const Home = () => {
  return (
    <motion.div
      id="home"
      className="home-container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5, ease: "easeInOut" }}
    >
      <div className="home-center">
        <div className="home-intro">
          <div className="home-status">
            <div className="home-status-container">
              <p className="home-status-circle"></p>
              <p>Available to work</p>
            </div>
          </div>
          <p className="home-intro-name">Hey, I'm Fiza.</p>
          <p className="home-intro-head">A Frontend Developer</p>
          <p className="home-intro-sub">
            Welcome to my digital playground! I love turning ideas into awesome
            web experiences.
            <br /> Check out my projects to see what I've been up to!
          </p>
          <div className="download-cv">
            <DownloadBtn />
          </div>
          <div className="home-marquee">
            <Marquee />
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Home;

import React, { useState } from "react";
import "./Navbar.css";
import { HashRouter as Router } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import { BsGithub } from "react-icons/bs";
import { FaLinkedin } from "react-icons/fa";
import { GoArrowUpRight } from "react-icons/go";
import { motion } from "framer-motion";
import { CgMenu } from "react-icons/cg";
import SideNav from "./SideNav";

const Navbar = ({ toggleSidebar }) => {
  const [showSidebar, setShowSidebar] = useState(false);

  const toggleSidebarVisibility = () => {
    setShowSidebar(!showSidebar);
  };

  return (
    <Router>
      <motion.nav
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5, ease: "easeInOut" }}
        className="nav"
      >
        <div className="nav-container">
          <div className="nav-left">FJ</div>
          <div className="nav-center">
            <Link smooth to="#home" className="hover-effect">
              Home
            </Link>
            <Link smooth to="#skills" className="hover-effect">
              Proficiencies
            </Link>
            <Link smooth to="#project" className="hover-effect">
              Projects
            </Link>
            <Link smooth to="#about" className="hover-effect">
              About
            </Link>
          </div>
          <div className="nav-right">
            <Link
              to="https://github.com/Fiza-04"
              target="__blank"
              className="nav-socials git"
            >
              <BsGithub />
            </Link>
            <Link
              to="https://www.linkedin.com/in/fiza-joiya/"
              target="__blank"
              className="nav-socials linkedin"
            >
              <FaLinkedin />
            </Link>
            <Link smooth to="#contact" className="nav-connect">
              <p>Let's Connect</p>
              <span>
                <GoArrowUpRight />
              </span>
            </Link>
            <CgMenu className="menu-icon" onClick={toggleSidebarVisibility} />
          </div>
        </div>
      </motion.nav>
      <SideNav show={showSidebar} toggleSidebar={toggleSidebarVisibility} />
    </Router>
  );
};

export default Navbar;

import React, { useEffect } from "react";
import "./DownloadBtn.css";
import { FaCheckCircle, FaDownload } from "react-icons/fa";

const DownloadBtn = () => {
  useEffect(() => {
    const btn = document.querySelector(".downloadBtn");
    const handleClick = (event) => {
      event.preventDefault();

      const downloadLink = document.createElement("a");
      downloadLink.href = "./Resume.pdf";
      downloadLink.download = "Fiza_Resume.pdf";
      downloadLink.click();

      btn.classList.add("active");

      const handleAnimationEnd = () => {
        btn.classList.remove("active");
        btn.removeEventListener("animationend", handleAnimationEnd);
      };

      btn.addEventListener("animationend", handleAnimationEnd);
    };

    btn.addEventListener("click", handleClick);

    return () => {
      btn.removeEventListener("click", handleClick);
    };
  }, []);

  return (
    <a href="#" className="downloadBtn">
      <div className="download-completed">
        <span className="flex flex-row space-x-1">
          <FaCheckCircle className="download-icon" />
          <p>Completed</p>
        </span>
      </div>
      <div className="download">
        <span className="flex flex-row space-x-2">
          <FaDownload className="download-icon" />
          <p>Download CV</p>
        </span>
      </div>
    </a>
  );
};

export default DownloadBtn;

import "./App.css";
import Navbar from "./components/Navbar";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import Projects from "./pages/Projects";
import Skills from "./pages/Skills";

function App() {
  return (
    <div className="overflow-x-hidden antialiased">
      <div className="fixed top-0 -z-10 h-full w-full">
        <div className="app-bg"></div>
      </div>
      <div className="container mx-auto px-8">
        <Navbar />
        <Home />
        <Skills />
        <Projects />
        <About />
        <Contact />
      </div>
    </div>
  );
}

export default App;

import React from "react";
import "./Marquee.css";
import MarqueeItems from "./MarqueeItems";

const Marquee = () => {
  const upperMarquee = [
    "/images/html-1.svg",
    "/images/css-3.svg",
    "/images/javascript-1.svg",
    "/images/react-2.svg",
    "/images/redux.svg",
    "/images/nextjs-13.svg",
    "/images/nodejs-3.svg",
    "/images/express-109.svg",
    "/images/jquery-1.svg",
    "/images/ajax-1.svg",
    "/images/figma-icon.svg",
    "/images/framer-motion.svg",
    "/images/git-bash.svg",
    "/images/github-icon-2.svg",
    "/images/linux-tux-1.svg",
  ];

  return (
    <div className="home-marquee-container">
      <MarqueeItems images={upperMarquee} from={0} to={"-100%"} />
    </div>
  );
};

export default Marquee;

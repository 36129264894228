import React, { useState, useRef } from "react";
import { motion } from "framer-motion";
import "./Contact.css";
import { FiSend } from "react-icons/fi";
import { CgMail } from "react-icons/cg";
import { FaGithub } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import emailjs from "@emailjs/browser";
import { Toaster, toast } from "sonner";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_wqtws1s", "template_dhn3as6", form.current, {
        publicKey: "xtfIvOIg5k7aeWJJF",
      })
      .then(
        () => {
          console.log("SUCCESS!");
          setFormData({
            name: "",
            email: "",
            message: "",
          });
          toast.success("Message sent successfully!");
        },
        (error) => {
          console.log("FAILED...", error.text);
          // Optionally, display an error message
          alert("Failed to send message. Please try again later.");
        }
      );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const fadeInFromBottomVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: { opacity: 1, y: 0 },
  };

  const transition = { duration: 1.2, ease: "easeOut" };

  const handleIconClick = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const handleMailClick = () => {
    window.location.href = "mailto:fizajoiya28@gmail.com";
  };

  return (
    <motion.div
      id="contact"
      className="contact-container"
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.1 }}
      transition={transition}
      variants={fadeInFromBottomVariants}
    >
      <motion.div
        className="contact-text"
        variants={fadeInFromBottomVariants}
        transition={{ ...transition, delay: 0.2 }}
      >
        <motion.h1
          variants={fadeInFromBottomVariants}
          transition={{ ...transition, delay: 0.4 }}
        >
          Connect With Me
        </motion.h1>
        <motion.p
          variants={fadeInFromBottomVariants}
          transition={{ ...transition, delay: 0.6 }}
        >
          Reach out and let's make something great happen. Drop me a note, and
          I'll get back to you in a flash.
        </motion.p>
        <div className="contact-icons">
          <motion.span
            className="mail"
            whileHover={{ scale: 1.1 }}
            variants={fadeInFromBottomVariants}
            transition={{ ...transition, delay: 0.8 }}
            onClick={handleMailClick}
          >
            <CgMail />
          </motion.span>
          <motion.span
            whileHover={{ scale: 1.1 }}
            variants={fadeInFromBottomVariants}
            transition={{ ...transition, delay: 1 }}
            onClick={() =>
              handleIconClick("https://www.linkedin.com/in/fiza-joiya/")
            }
            className="linked"
          >
            <FaLinkedin />
          </motion.span>
          <motion.span
            whileHover={{ scale: 1.1 }}
            variants={fadeInFromBottomVariants}
            transition={{ ...transition, delay: 1.2 }}
            onClick={() => handleIconClick("https://github.com/Fiza-04")}
            className="github"
          >
            <FaGithub />
          </motion.span>
        </div>
      </motion.div>
      <motion.form
        ref={form}
        onSubmit={sendEmail}
        className="contact-form"
        variants={fadeInFromBottomVariants}
        transition={{ ...transition, delay: 1.4 }}
      >
        <motion.div
          className="input-div"
          variants={fadeInFromBottomVariants}
          transition={{ ...transition, delay: 1.6 }}
        >
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="input"
            placeholder="Name"
            required
          />
          <span htmlFor="name" className="input-label">
            Name
          </span>
        </motion.div>
        <motion.div
          className="input-div"
          variants={fadeInFromBottomVariants}
          transition={{ ...transition, delay: 1.8 }}
        >
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="input"
            placeholder="Email"
            required
          />
          <span htmlFor="email" className="input-label">
            Email
          </span>
        </motion.div>
        <motion.div
          className="input-div"
          variants={fadeInFromBottomVariants}
          transition={{ ...transition, delay: 2 }}
        >
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            className="input"
            rows="5"
            placeholder="Message"
            required
          ></textarea>
          <span htmlFor="message" className="input-label">
            Your Message
          </span>
        </motion.div>
        <motion.button
          type="submit"
          className="submit-btn"
          variants={fadeInFromBottomVariants}
          transition={{ ...transition, delay: 2.2 }}
        >
          <span>Send Message</span>
          <FiSend className="send" />
        </motion.button>
      </motion.form>
      <Toaster richColors />
    </motion.div>
  );
};

export default Contact;

import React from "react";
import { motion } from "framer-motion";
import { CgClose } from "react-icons/cg";
import { HashLink as Link } from "react-router-hash-link";
import "./SideNav.css";

const variants = {
  open: { opacity: 1, x: 0 },
  closed: { opacity: 0, x: "100%" },
};

function SideNav({ show, toggleSidebar }) {
  const data = [
    { title: "Home", path: "#home" },
    { title: "Proficiencies", path: "#skills" },
    { title: "Projects", path: "#project" },
    { title: "About", path: "#about" },
    { title: "Contact", path: "#contact" },
  ];

  return (
    <div>
      <motion.nav
        initial={false}
        animate={show ? "open" : "closed"}
        variants={variants}
        transition={{ type: "tween", duration: 0.5 }}
        className="side-nav"
      >
        <motion.button
          onClick={toggleSidebar}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          className="text-white"
        >
          <CgClose size={24} />
        </motion.button>
        <div className="nav-titles">
          {data.map((item, index) => (
            <div key={index} className="flex items-center cursor-pointer">
              <Link
                smooth
                to={item.path}
                className="header-anime"
                onClick={toggleSidebar}
              >
                {item.title}
              </Link>
            </div>
          ))}
        </div>
      </motion.nav>
    </div>
  );
}

export default SideNav;
